import React  from 'react';
import '../Styles/Gallery.css';
import img04  from "../Assets/gallery/img04.jpg";
import img08  from "../Assets/gallery/img08.jpg";
import img09  from "../Assets/gallery/img09.jpg";
import img10  from "../Assets/gallery/img10.jpg";
import img11  from "../Assets/gallery/img11.jpg";
import img12  from "../Assets/gallery/img12.jpg";
import img13  from "../Assets/gallery/img13.jpg";
import img14  from "../Assets/gallery/img14.jpg";
import img15  from "../Assets/gallery/img15.jpg";
import img16  from "../Assets/gallery/img16.jpg";
import img17  from "../Assets/gallery/img17.jpg";
import img18  from "../Assets/gallery/img18.jpg";
import { Row, Col } from 'react-bootstrap';
import { Gallery, Item } from 'react-photoswipe-gallery';


function PhotoGallery() {
  
    return (
        <div>
              <section className="galleryMainPage">
                <div className="galleryContainerPage">
                <p className='HeadMainGallery'>Gallery</p>
                <Gallery>
                        <Row className='spacePhotos'>
                            <Col className='mobileSpacePhotos' sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img04}
                                    thumbnail={img04}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} alt="irock2022" onClick={open} src={img04} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col className='mobileSpacePhotos' sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img08}
                                    thumbnail={img08}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img08} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img09}
                                    thumbnail={img09}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img09} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        <Row className='spacePhotos'>
                            <Col className='mobileSpacePhotos' sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img14}
                                    thumbnail={img14}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} alt="irock2022" onClick={open} src={img14} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col className='mobileSpacePhotos' sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img11}
                                    thumbnail={img11}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img11} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img12}
                                    thumbnail={img12}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img12} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        <Row className='spacePhotos'>
                            <Col className='mobileSpacePhotos' sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img15}
                                    thumbnail={img15}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} alt="irock2022" onClick={open} src={img15} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col className='mobileSpacePhotos' sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img18}
                                    thumbnail={img18}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img18} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img17}
                                    thumbnail={img17}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img17} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                        </Row>
                        <Row className='spacePhotos'>
                            <Col className='mobileSpacePhotos' sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img10}
                                    thumbnail={img10}
                                    width='794'
                                    height='1410'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} alt="irock2022" onClick={open} src={img10} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col className='mobileSpacePhotos' sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img13}
                                    thumbnail={img13}
                                    width='794'
                                    height='1410'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img13} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <Item
                                    original={img16}
                                    thumbnail={img16}
                                    width='794'
                                    height='1410'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img16} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                        </Row>
                </Gallery>
                </div>
                
            </section>
        </div>
    )
}

export default PhotoGallery