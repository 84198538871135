import React from 'react';
import '../Styles/LineUp.css';
import { Container, Row, Col } from 'react-bootstrap';
import BM from "../Assets/Artists/BM.png";
import swarathma from "../Assets/Artists/swarathma.png";
import agam from "../Assets/Artists/agam.png";
import Parikrama from "../Assets/Artists/parikrama.png";    
import gatc from "../Assets/Artists/girish.png";
import pineappleexpress from "../Assets/Artists/pineappleexpress.png";
import mama from "../Assets/Artists/mama.png";
import tillapes from "../Assets/Artists/tillapes.png";
import tot from "../Assets/Artists/tot.png";
import underside from "../Assets/Artists/underside.png";

function LineUp() {

    return (
        <div>
            <Container fluid>
                <div className='lineUpBlock'>
                <h1 data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" className='irock_head_lineup'>Line Up</h1>
                    <Row className='space_bottom_row'>
                        <Col className='space_bottom_col' sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={BM} alt="Bhayank amut independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>Bhayanak Maut</h2>
                                    {/* <p className='artistPara'>Formed in 1991, Parikrama is the premier Rock & Roll export from India. The band incorporates many Indian instruments, such as the violin, the Indian bamboo flute, the sitar, and the tabla.
                                        It is known for its unique sound worldwide and has performed at the mecca of Rock & Roll, the Download Festival. The band's most famous release, But It Rained, is still one of the most popular songs on the Indian independent scene.
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={swarathma} alt="swarathma independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>Swarathma</h2>
                                    {/* <p className='artistPara'>Parvaaz is a contemporary Indian music band whose sound can best be described as a blend of prog/psychedelic rock with folk and world music elements. They have been a hot favorite in the independent music community for a few years.
                                        They were on the cover of Rolling Stone India for May (2018), with the magazine calling them "India's Most Exciting Band." and have also won the Toto Music award (2015) and the Judge's Choice for "Best Rock Artist" at the Radio City Freedom Awards (2017)
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='space_bottom_row'>
                        <Col className='space_bottom_col' sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={Parikrama} alt="Parikrama independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>Parikrama</h2>
                                    {/* <p className='artistPara'>Thaikkudam Bridge arrived in 2013 as their first studio jam show; Music Mojo became insanely popular. The band has not looked back ever since, clocking 300+ gigs to date. The band is loved by millions of fans worldwide and has been the biggest breakout band to emerge from India.
                                        <br></br>
                                        The band is an amalgamation of numerous musical genres because the artists Hail from different parts of the country with myriad musical backgrounds. From Indian Classical to Folk to Hard Rock, the band has a rich repertoire of popular tracks.
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={agam} alt="agam independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>agam</h2>
                                    {/* <p className='artistPara'>The word unique is often overused, but rarely is it as authentic as the Indian post-rock band aswekeepsearching. Even in a genre that is increasingly getting saturated, there is no other Hindi post-rock act like the cathartic trio. Offering energetic performances and an immersive audio-visual experience, aswekeepsearching is one experience you shouldn't miss!
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='space_bottom_row'>
                        <Col className='space_bottom_col' sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={gatc} alt="gatc independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>girish & the chronicles </h2>
                                    {/* <p className='artistPara'>Zero is an Alternative Rock band from Mumbai, formed in 1998. They are widely regarded as one of the best bands to have come out of the Indian alternate music circuit, with a massive fan following. Their second album, Hook, was a major hit and was ranked as the #1 album of the decade by Indiecision.com.   <br></br>
                                        They are regarded as pioneering and one of the best bands in the Indian rock music circuit.
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={pineappleexpress} alt="pineappleexpress independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>pineappleexpress</h2>
                                    {/* <p className='artistPara'>For over ten years, Chennai based four-piece The F16s have been churning towards an ongoing refinement of their vision. From their 2013 debut Kaleidoscope to the 2014 studio tie up with Converse that birthed "Nobody's Gonna Wait", through to their 2016 breakthrough "Triggerpunkte", this vision has mirrored both a maturity of execution and a certain gravity from listeners worldwide - as ten million Spotify streams and coverage in StereoGum, Brooklyn Vegan and Variety clearly suggest.
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='space_bottom_row'>
                        <Col className='space_bottom_col' sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={mama} alt="mama tips & mama bois independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>mama tips & <br></br> mama's bois</h2>
                                    {/* <p className='artistPara'>True to their name, Avial blends Malayalam Poetry with Contemporary Alternative Rock Music.
                                        Avial’s music has overcome language barriers not only in India but has also drawn fans across the sub-continent and the globe. It has an increasing fan following, with a revolutionary approach in today’s contemporary music scene.  <br></br>
                                        In search of something more intrinsic, with a sense of belonging, Avial discovered and articulated the expression in their own mother tongue, dared to express their music with their self-titled debut album.
                                        Fetching a great name for themselves, Avial is one of the most sought after Indian bands today, transcending genres, justifying the term “Alternative Malayalam Rock”
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={tillapes} alt="tillapes independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>tillapes</h2>
                                    {/* <p className='artistPara'>India’s front-running band for over 25 years, Indus Creed, is considered the pioneer of rock music in the country. They blazed trails across India well before the advent of satellite TV into the country’s airwaves, setting the stage for today’s fertile indie scene. Their pathbreaking albums are still considered seminal works.
                                        They are a multi-award-winning band that first put India on the International Rock Map.
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='space_bottom_row'>
                    <Col className='space_bottom_col' sm={12} xs={12} md={12} lg={6} xl={6}>
                    <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={underside} alt="Underside independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>tough on tobacco <br></br> ft. bombay brass</h2>
                                    {/* <p className='artistPara'>Pentagram came together in 1994 with a strong need to play individual, original, unique music. The alternative rock band is known for creating new sounds that come out of a burning need to find a way to break free and uniquely represent their emotions.
                                        Pioneers of the Indian Music scene, Pentagram introduced electronic-rock to India and toured extensively all over India and abroad. They are also among India's most incendiary live performers.
                                        Their music has been recognized worldwide, and they have shared the stage with contemporary giants like the Prodigy, Coldplay, Chemical Brothers, Tori Amos, Fat Boy Slim, and White Stripes.
                                    </p> */}
                                </div>
                            </div>
                    </Col>
                        <Col className='space_bottom_col' sm={12} xs={12} md={12} lg={6} xl={6}>
                            <div className='artistColumn'>
                                <div>
                                    <img data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistImage' src={tot} alt="TOT independence rock"></img>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='artistInfo'>
                                    <h2 className='artistName'>tough on tobacco <br></br> ft. bombay brass</h2>
                                    {/* <p className='artistPara'>Pentagram came together in 1994 with a strong need to play individual, original, unique music. The alternative rock band is known for creating new sounds that come out of a burning need to find a way to break free and uniquely represent their emotions.
                                        Pioneers of the Indian Music scene, Pentagram introduced electronic-rock to India and toured extensively all over India and abroad. They are also among India's most incendiary live performers.
                                        Their music has been recognized worldwide, and they have shared the stage with contemporary giants like the Prodigy, Coldplay, Chemical Brothers, Tori Amos, Fat Boy Slim, and White Stripes.
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default LineUp