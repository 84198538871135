import React  from 'react';
import '../Styles/FAQ.css';
import { Container, Row, Col} from 'react-bootstrap';
function FAQ() {
  
    return (
        <div>
            <section className='faq_block'>
                <div>
                    <Container fluid>
                        <div className='faq_bg'>
                        <div className='heading_faq'>
                            <h1 data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" className='faq_head'>FREQUENTLY ASKED QUESTIONS</h1>
                        </div>
                        <Row className='space_bottom_faq'>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Do I have to be fully vaccinated (2 doses) to attend the festival? </h3>
                                    <p className='faq_para'>Yes, you need to be fully vaccinated to attend the festival. By purchasing the ticket, you are declaring that you have taken both the vaccine shots at least 14 days prior to the festival date. </p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards space_ud'>
                                    <h3 className='faq_head_card'>Is there a vaccination verification process at the venue?</h3>
                                    <p className='faq_para'>Yes. We will be verifying the vaccination certificate with a government-approved system. For fans who have taken their vaccines in countries other than India, there will be a separate counter to verify the same. </p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Can I attend Independence Rock if I am not vaccinated but I have a negative RT-PCR report?</h3>
                                    <p className='faq_para'>No, as per government regulations we are only allowing fully vaccinated guests (2 doses) to attend the event. You will need to have your second dose at least 14 days prior to the festival date. </p>
                                </div>
                            </Col>
                        </Row>
                        {/* second */}
                        <Row className='space_bottom_faq'>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>What if I got vaccinated outside India? Can I still attend the festival?</h3>
                                    <p className='faq_para'>Please DM us @independencerock on Instagram and share your scans of your vaccination certificates and we will get back to you at the earliest. </p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards space_ud'>
                                    <h3 className='faq_head_card'>What happens if I don't get through the thermal scanning checkpoint?</h3>
                                    <p className='faq_para'>If the thermal scanner shows your temperature is high, we will have no choice but to deny you entry. </p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Do you have medical assistance on the ground?</h3>
                                    <p className='faq_para'>Yes, there will be on-ground medical professionals as well as an ambulance on standby. </p>
                                </div>
                            </Col>
                        </Row>
                        {/* third */}
                        <Row className='space_bottom_faq'>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Is the festival wheelchair accessible? </h3>
                                    <p className='faq_para'>Yes</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards space_ud'>
                                    <h3 className='faq_head_card'>Do I have to bring a printout of my festival ticket?</h3>
                                    <p className='faq_para'>Independence Rock will always strive to be green and clean. You can store your ticket PDF file on your phone and simply show that at the box office. Be warned that there is limited mobile network at the venue, so remember to save the ticket on your phone instead of accessing it on the web / through the Paytm Insider app. You can also show the SMS sent by Paytm Insider at the time of ticket purchase.</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out"  className='faq_cards'>
                                    <h3 className='faq_head_card'>What is the age limit to attend the event?</h3>
                                    <p className='faq_para'>Audiences of the age of 16 years & above can attend the event by purchasing a ticket provided they are fully vaccinated at least 14 days prior to the festival. Audiences below the age of 16 to be accompanied by an adult</p>
                                </div>
                            </Col>
                        </Row>
                        {/* fourth */}
                        <Row className='space_bottom_faq'>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Can I cancel my ticket? </h3>
                                    <p className='faq_para'>Once booked, tickets are non-refundable / non-transferable.</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards space_ud'>
                                    <h3 className='faq_head_card'>When will I receive my wristband?</h3>
                                    <p className='faq_para'>You can collect your wristband at the box office before you enter the festival upon showing a valid e-ticket confirmation.</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>What kind of ID do I need?</h3>
                                    <p className='faq_para'>Any valid, government-issued ID, such as a passport, Aadhar card, PAN Card, or driver’s license is good enough to validate your identity when entering the festival. </p>
                                </div>
                            </Col>
                        </Row>
                        {/* fifth */}
                        <Row className='space_bottom_faq'>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>What if I lose my band mid-event and need to get out for a bit, can I re-enter?</h3>
                                    <p className='faq_para'>Wristbands that are lost or misplaced before or during the festival will not be replaced. You will not be allowed to re-enter the festival without your wristband so we would suggest you keep your wristband on for the duration of the festival! </p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards space_ud'>
                                    <h3 className='faq_head_card'>Is alcohol available at the event?</h3>
                                    <p className='faq_para'>Yes, alcohol will be served at the festival, but you’ll only be served alcohol if you’re above the legal drinking age! (For clarity’s sake, it’s 21 for beer and wine and 25 for all other spirits). Drink responsibly at the festival, since everyone there is looking to have a good time!</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Can I bring my alcohol and make it a BYOB gig?</h3>
                                    <p className='faq_para'>There will be ample amounts of food and beverage served at the festival. Once there you will need to ensure all outside perishables are done with or disposed of since they will not be permitted into the festival.</p>
                                </div>
                            </Col>
                        </Row>
                        {/* sixth */}
                        <Row className='space_bottom_faq'>
                            <Col sm={12} xs={12} md={12} lg={12} xl={12}>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards customHeight'>
                                    <h3 className='faq_head_card'>What all can I bring with me to the festival then?</h3>
                                    <p className='faq_para'>We encourage you to be handy and dandy at iRock – a basic checklist should have your mask (super important), your mobile phone, your wallet / purse, sanitizer (one can never be too safe, right?),</p>
                                    <p className='faq_para'>Things that aren’t allowed are:</p>
                                    <p className='faq_para'>- Illegal substances, drugs, related paraphernalia </p>
                                    <p className='faq_para'>- Large chains or spiked jewellery </p>
                                    <p className='faq_para'>- Weapons of any kind - including pocketknives, pepper spray, guns, fireworks, or any explosives. </p>
                                    <p className='faq_para'>- Outside food or beverages (including alcohol). </p>
                                    <p className='faq_para'>- Professional photo, video or audio equipment (No detachable lenses, tripods, big zooms or commercial-use rigs) </p>
                                </div>
                            </Col>
                        </Row>
                        {/* seventh */}
                        <Row className='space_bottom_faq'>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Will you confiscate my bag at the entry?</h3>
                                    <p className='faq_para'>No, your bag will not be confiscated at the entry; however, we will need to check the elements in your bag to ensure none of the things listed above are present.  
                                    That festival will be strictly drug-free, and any audiences’ members found with illegal substances will be handed over to the authorities.  </p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards space_ud'>
                                    <h3 className='faq_head_card'>What time does the event start and end?</h3>
                                    <p className='faq_para'>Entry to the festival begins at 4:00 PM* on each day, and the festival ends at 11:00 PM. *Indicative timings only, Subject to change basis final plans.</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Is parking available at the festival?</h3>
                                    <p className='faq_para'>Yes. The venue has ample parking space to accommodate your vehicle. Parking vehicles at the designated parking lot is at the owners risk. </p>
                                </div>
                            </Col>
                        </Row>
                        {/* eight */}
                        <Row className='space_bottom_faq'>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>Are pets allowed?</h3>
                                    <p className='faq_para'>Sorry, but the festival will not permit pets. Please keep your pets home safe and sound to ensure everyone, yourself included, has a hassle-free experience at the festival.</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards space_ud'>
                                    <h3 className='faq_head_card'>Is there an official hashtag? </h3>
                                    <p className='faq_para'>#MahindraIndependenceRock</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={12} lg={4} xl={4}>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='faq_cards'>
                                    <h3 className='faq_head_card'>I have a query that has not been addressed in the FAQs. Who can I contact to help me with this? </h3>
                                    <p className='faq_para'>You can DM us on @independencerock on Instagram and someone from our team will respond to you as soon as they can. </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    </Container>
                </div>
            </section>
        </div>
    )
}

export default FAQ