import React, { useEffect, useState, useRef } from "react";
import '../Styles/Home.css';
import maintitle from "../Assets/irocklogo.png";
import frameSlider from "../Assets/Home/frame.png";
import mobileFrameSecond from "../Assets/Home/mobileSecondFrame.png";
// import '../Styles/PhotoGallery.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import img01  from "../Assets/gallery/img01.jpg";
import img02  from "../Assets/gallery/img02.jpg";
import img03  from "../Assets/gallery/img03.jpg";
import img05  from "../Assets/gallery/img05.jpg";
import img06  from "../Assets/gallery/img06.jpg";
import img07  from "../Assets/gallery/img07.jpg";

// Slider Videos


import jawa from "../Assets/Home/sponsors/jawa.svg";
import mahindra from "../Assets/Home/sponsors/mahindra.png";
import coSponsorsBy from "../Assets/Home/sponsors/coSponsorsBy.png";
import promotedBy from "../Assets/Home/sponsors/promotedBy.png";
// 3
import firstIntroVideo from "../Assets/Video/IrockPromoWeb.mp4";
import firstIntroVideoM from "../Assets/Video/irockAftMob.mp4";
import promoAniVideo from "../Assets/Video/IRock_v8.mp4";
import promoAniVideoM from "../Assets/Video/irockMobile.mp4";
import promoAniVideoPoster from "../Assets/Video/promoAniVideoPoster.jpg";
import posterMain2023 from "../Assets/Home/poster.png";
import posterMain2023M from "../Assets/Video/posterMain2023M.png";
import irockMPoster from "../Assets/Video/irockMPoster.png";
// 
import { Row, Col } from 'react-bootstrap';

// video poster



import instagramLogo from "../Assets/Home/instagram.svg";
import facebookLogo from "../Assets/Home/facebook.svg";
import VisibilitySensor from 'react-visibility-sensor';
import { Carousel, Container } from 'react-bootstrap';
import SwiperCore, { Pagination, Autoplay, Navigation, FreeMode } from 'swiper';


// Import Swiper styles

SwiperCore.use([Pagination, Autoplay, Navigation, FreeMode]);


function Home() {
    const [index, setIndex] = useState(0);

    const videoRef = useRef(null);
    const videoRefM = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleM, setIsVisibleM] = useState(false);



    useEffect(() => {
        if (isVisible) {
            videoRef.current.play();
        } else {
            if (videoRef.current.play) {
                videoRef.current.pause();
            }
        }
    }, [isVisible]);



    useEffect(() => {
        if (isVisibleM) {
            videoRefM.current.play();
        } else {
            if (videoRefM.current.play) {
                videoRefM.current.pause();
            }
        }
    }, [isVisibleM]);

    return (
        <div>
            <section className='first_fold'>

                <div className="desktopView">
                    <Carousel className="customSliderTop" interval={null}>
                    <Carousel.Item>
                            <div data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='relative_frames'>
                            <img className='imgFrameNew' src={frameSlider} alt="iRock Frame"></img>
                            <div className='content_slider'>
                            <p className="caption_slider_first colorCyan">Band Hunt</p>
                                <p className='caption_slider_new_fbh'>Think you have what it takes to be Tomorrow’s Headliners?</p>
                                <div className="space_top">
                                    <a className="butttonSlider" rel="noopener noreferrer" href="https://insider.in/mahindra-independence-rock-2023-tomorrows-headliners-band-hunt-oct-2023/event" target="_blank">Register now</a>
                                </div>
                            </div>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='relative_frames'>
                            <img className='imgFrameNew' src={frameSlider} alt="iRock Frame"></img>
                            <div className='content_slider'>
                                <h1 className='caption_slider_first white'>
                                   <span className="colorCyan">Independence Rock </span> <br></br>
                                    Home of the headbang since 1986  <br></br>
                                    4-5 November 2023  <br></br>
                                    Bayview Lawns  <br></br>
                                    {/* Get Ready  <br></br> */}
                                </h1>
                            </div>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="containerr">
                                <img className='imgFrameNew' src={frameSlider} alt="iRock Frame"></img>
                                <div className="overlay">
                                    <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
                                        <video style={{ width: '81%', borderRadius: "8px" }} ref={videoRef} controls autoPlay poster={promoAniVideoPoster}>
                                            <source src={promoAniVideo} type="video/mp4"></source>
                                        </video>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="containerr">
                                <img className='imgFrameNew' src={frameSlider} alt="iRock Frame"></img>
                                <div className="overlay">
                                        <video style={{ width: '81%', borderRadius: "8px" }} controls poster={posterMain2023}>
                                            <source src={firstIntroVideo} type="video/mp4"></source>
                                        </video>
                                </div>

                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="mobileView">
                    <Carousel className="customSliderTop" interval={null}>
                    <Carousel.Item>
                            <div className='relative_frames'>
                                <img className='imgFrameSecond' src={mobileFrameSecond} alt="iRock Frame"></img>
                                <div className='content_slider_second'>
                                <h2 className='irock_head center_txt'>Band Hunt</h2>
                                    <p className='about_para_mbh center_txt'>Think you have what it takes to be Tomorrow’s Headliners?</p>
                                    <div className="space_top">
                                        <a className="butttonSlider" rel="noopener noreferrer" href="https://insider.in/mahindra-independence-rock-2023-tomorrows-headliners-band-hunt-oct-2023/event" target="_blank">Register Now</a>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='relative_frames'>
                                <img className='imgFrameSecond' src={mobileFrameSecond} alt="iRock Frame"></img>
                                <div className='content_slider_second'>
                                    <h2 className='irock_head white'>
                                        <span className="colorCyan">Independence Rock </span> <br></br>
                                    Home of the headbang since 1986  <br></br>
                                    4-5 November 2023  <br></br>
                                    Bayview Lawns  <br></br>
                                    {/* Get Ready  <br></br> */}
                                    </h2>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="containerr">
                                <img className='imgFrameSecond' src={mobileFrameSecond} alt="iRock Frame"></img>
                                <div className="overlayMobile">
                                    <VisibilitySensor onChange={(isVisibleM) => setIsVisibleM(isVisibleM)}>
                                        <video style={{ width: '83%', borderRadius: "8px" }} poster={irockMPoster} controls autoPlay ref={videoRefM}>
                                            <source src={promoAniVideoM} type="video/mp4"></source>
                                        </video>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="containerr">
                                <img className='imgFrameSecond' src={mobileFrameSecond} alt="iRock Frame"></img>
                                <div className="overlayMobile">
                                      <video style={{ width: '83%', borderRadius: "8px" }} poster={posterMain2023M} controls>
                                            <source src={firstIntroVideoM} type="video/mp4"></source>
                                        </video>
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section>
            <section className="desktopView contentSliderSecond">
                <div>
                <Carousel className="customSlider" fade>
                    <Carousel.Item>
                        <div data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='relative_frames'>
                            <img className='imgFrame' src={frameSlider} alt="iRock Frame"></img>
                            <div className='content_slider'>
                                <p className="headSliderNew">Get ‘em while they’re hot</p>
                                <p className='caption_slider_new'>Tickets are out. The stage is set. Are you coming?
</p>
                                <div className="space_top">
                                    <a className="butttonSlider" rel="noopener noreferrer" href="https://insider.in/mahindra-independence-rock-2023-nov4-2023/event?utm_source=IndependenceRock&utm_medium=Website" target="_blank">Get Your Tickets Now</a>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* <Carousel.Item>
                        <div data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='relative_frames'>
                            <img className='imgFrame' src={frameSlider} alt="iRock Frame"></img>
                            <div className='content_slider'>
                                <p className="headSliderNew">Get ‘em while they’re hot</p>
                                <p className='caption_slider_new'>Tickets are out. The stage is set. Are you coming?</p>
                                <div className="space_top">
                                    <a className="butttonSlider" rel="noopener noreferrer" href="#" target="_blank">Get your tickets</a>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item> */}
                    <Carousel.Item>
                        <div data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='relative_frames'>
                            <img className='imgFrame' src={frameSlider} alt="iRock Frame"></img>
                            <div className='content_slider'>
                                <p className="headSliderNew">Venue</p>
                                <p className='caption_slider_new'>Going hard is always better with a view.
                                <br></br>
                                Get ready to rock at Mazgaon Docks.</p>
                            </div>
                        </div>
                    </Carousel.Item>
                    
                </Carousel>
                </div>
            </section>
            <section className='info_block mobileView'>
                <div className="second_slider">
                    <Carousel className="customSliderSecond">
                    <Carousel.Item>
                            <div className='relative_frames'>
                                <img className='imgFrameSecond' src={mobileFrameSecond} alt="iRock Frame"></img>
                                <div className='content_slider_second'>
                                    <h2 className='irock_head'>Get ‘em while they’re hot</h2>
                                    <p className='about_para'>Tickets are out. The stage is set. Are you coming?
                                    </p>
                                    <div className="space_top">
                                        <a className="butttonSlider" rel="noopener noreferrer" href="https://insider.in/mahindra-independence-rock-2023-nov4-2023/event?utm_source=IndependenceRock&utm_medium=Website" target="_blank">Get Your Tickets Now</a>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        {/* <Carousel.Item>
                            <div className='relative_frames'>
                                <img className='imgFrameSecond' src={mobileFrameSecond} alt="iRock Frame"></img>
                                <div className='content_slider_second'>
                                    <h2 className='irock_head'>Get ‘em while they’re hot</h2>
                                    <p className='about_para'>Tickets are out. The stage is set. Are you coming?
                                    </p>
                                    <div className="space_top">
                                        <a className="butttonSlider" rel="noopener noreferrer" href="#" target="_blank">Get your tickets</a>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item> */}
                        <Carousel.Item>
                            <div className='relative_frames'>
                                <img className='imgFrameSecond' src={mobileFrameSecond} alt="iRock Frame"></img>
                                <div className='content_slider_second'>
                                    <h2 className='irock_head center_txt'>Venue</h2>
                                    <p className='about_para center_txt'>Going hard is always better with a view.
                                Get ready to rock at Mazgaon Docks.</p>
                                    {/* <div className="space_top">
                                        <a className="butttonSlider" rel="noopener noreferrer" href="#" target="_blank">View Location</a>
                                    </div> */}
                                </div>
                            </div>
                        </Carousel.Item>
                       
                    </Carousel>
                </div>
            </section>
            <section className="second_fold">
                <div data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='title'>
                    <img className='title_irock zoom-in-zoom-out' src={maintitle} alt="iRock logo"></img>
                </div>
            </section>
            <section className="galleryMainHome">
                <div className="galleryContainerHome">
                <p className='HeadMainGallery'>Gallery</p>
                <Gallery>
                        <Row className='spacePhotos'>
                            <Col className='mobileSpacePhotos' sm={6} xs={6} md={6} lg={4} xl={4}>
                                <Item
                                    original={img01}
                                    thumbnail={img01}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} alt="irock2022" onClick={open} src={img01} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col className='mobileSpacePhotos' sm={6} xs={6} md={6} lg={4} xl={4}>
                                <Item
                                    original={img02}
                                    thumbnail={img02}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img02} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col sm={6} xs={6} md={6} lg={4} xl={4}>
                                <Item
                                    original={img03}
                                    thumbnail={img03}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img03} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col className='mobileSpacePhotos' sm={6} xs={6} md={6} lg={4} xl={4}>
                                <Item
                                    original={img05}
                                    thumbnail={img05}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} alt="irock2022" onClick={open} src={img05} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col className='mobileSpacePhotos' sm={6} xs={6} md={6} lg={4} xl={4}>
                                <Item
                                    original={img06}
                                    thumbnail={img06}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img06} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                            <Col sm={6} xs={6} md={6} lg={4} xl={4}>
                                <Item
                                    original={img07}
                                    thumbnail={img07}
                                    width='1362'
                                    height='766'
                                >
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} alt="irock2022" src={img07} className="photogallerythumbnail" />
                                    )}
                                </Item>
                            </Col>
                        </Row>
                </Gallery>
                <div className="space_top_gallery center">
                    <a className="butttonSlider" rel="noopener noreferrer" href="/gallery">Visit Gallery</a>
                </div>
                </div>
                
            </section>
            <section className="sponsorsBlock">
                <div className='content_social'>
                    <h3 data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='lineUpHead center_txt'>Our Sponsor's</h3>
                </div>
                <div className="imgBlockSponsors">
                    <div className="spaceRightSponsors">
                        <p className="sponsorstag">Title Partner</p>
                        <img data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" src={mahindra} alt="Mahindra - iRock" className="sponsorsLogo"></img>
                    </div>
                    <div className="spaceRightSponsors">
                        <p className="sponsorstag">Ride Partner</p>
                        <img data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" src={jawa} alt="jawa - iRock" className="sponsorsLogo"></img>
                    </div>
                </div>

                <img data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" src={coSponsorsBy} className="coSponsorsByImg" alt="schweppes, tuborg" />
                <br></br>
                <img data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" src={promotedBy} className="promotedandproducedBy" alt="hyperlink, farhad wadia" />
            </section>
            <section className='footer_block'>
                <div data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="ease-in-out" className='footer_content'>
                    {/* <img src={footerLogo} className="fluid_img footer_img" alt="iRock logo"></img> */}
                    <p className='footer_txt'>Got questions?</p>
                    <p className='footer_txt_body'>Write to us at
                    </p>
                    <a href="mailto:rocknroll@independecerock.in" rel="noreferrer" className='footer_link_mail'> rocknroll@independecerock.in</a>
                    <br></br><br></br><br></br>
                    <p className='footer_txt'>And check us out at</p>
                    <div>
                        <a href="https://www.instagram.com/independencerock/" rel="noopener noreferrer" target="_blank">
                            <img className="socialStyle" src={instagramLogo} alt="iRock instagram logo"></img>
                        </a>
                        <a href="https://www.facebook.com/independencerock/?ref=page_internal" rel="noopener noreferrer" target="_blank">
                            <img className="socialStyle" src={facebookLogo} alt="iRock facebook logo"></img>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home