import React  from 'react';
import '../Styles/Header.css';
import { Container, Navbar, Nav} from 'react-bootstrap';
import maintitle  from "../Assets/irocklogo.png";


function Header() {
  
    return (
        <div>
            <Container >
                <div className='f_top'> 
                    <div className='desktop_menu'>
                        <Navbar fixed="top" expand="lg">
                            <Navbar.Brand href="/">
                                <img src={maintitle} className="style_logo zoom-in-zoom-out" alt='logo independence rock'></img>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll"/>
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                className="ml-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                                >
                                <Nav.Link className='nav_style' href="/aboutus">About Us</Nav.Link>
                                <Nav.Link className='nav_style' href="/lineup">lineup</Nav.Link>
                                <Nav.Link className='nav_style' href="/bandhunt">Band Hunt</Nav.Link>
                                <Nav.Link className='nav_style' href="/gallery">Gallery</Nav.Link>
                                {/* <Nav.Link className='nav_style' href="/faq">FAQ</Nav.Link> */}
                                <Nav.Link className='nav_style btnBookNow' href="https://insider.in/mahindra-independence-rock-2023-nov4-2023/event?utm_source=IndependenceRock&utm_medium=Website">Get Your Tickets Now</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                    <div>
                        <div>
                            <a href='/'>
                                <img src={maintitle} className="style_logo_m zoom-in-zoom-out" alt='logo independence rock'></img>
                            </a>
                        </div>
                        <div className='mobile_menu'>
                            <input type="checkbox" id="active"/>
                            <label htmlFor="active" className="menu-btn"><span></span></label>
                            <label htmlFor="active" className="close"></label>
                            <div className="wrapper">
                                <ul>
                                    <li><a href="/aboutus">About Us</a></li>
                                    <li><a href="/lineup">Line Up</a></li>
                                    <li><a href="/bandhunt">Band Hunt</a></li>
                                    <li><a href="/Gallery">Gallery</a></li>
                                    {/* <li><a href="/faq">FAQ</a></li> */}
                                    <li><a className='btnBookNow mFont' href="https://insider.in/mahindra-independence-rock-2023-nov4-2023/event?utm_source=IndependenceRock&utm_medium=Website" target="_blank" rel="noopener noreferrer">Get Your Tickets Now</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                </div>
            
            
            </Container>
        </div>
    )
}

export default Header