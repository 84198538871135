import './App.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import scrollTop from '../src/Assets/scrollToTop.svg'
import Home from './Components/Home.js';
import FAQ from './Components/FAQ';
import Header from './Components/Header';
import AboutUs from './Components/AboutUs';
import PhotoGallery from './Components/PhotoGallery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LineUp from './Components/LineUp';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import BandHunt from './Components/BandHunt';
import 'photoswipe/dist/photoswipe.css'



// Import Swiper styles




// document.addEventListener('contextmenu', event => event.preventDefault());
// document.onkeypress = function (event) {  
//   event = (event || window.event);  
//   if (event.keyCode === 123) {  
//   return false;  
//   }  
// }  
//   document.onmousedown = function (event) {  
//   event = (event || window.event);  
//   if (event.keyCode === 123) {  
//   return false;  
//   }  
// }  
//   document.onkeydown = function (event) {  
//   event = (event || window.event);  
//   if (event.keyCode === 123) {  
//   return false;  
//   }  
// } 


function App() {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <div>
      <div className="scroll-to-top">
       {isVisible && 
        <div onClick={scrollToTop}>
          <img className="scroll-top vert-move" src={scrollTop} alt='Go to top'/>
        </div>}
    <BrowserRouter>
    <Header/>
    <Routes>
        <Route path='/' index element={<Home />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/gallery' element={<PhotoGallery />} />
        <Route path='/lineup' element={<LineUp />} />
        <Route path='/bandhunt' element={<BandHunt />} />
        <Route path='/faq' element={<FAQ />} />
    </Routes>
    {/* <Footer/> */}
  </BrowserRouter>
  </div>
  </div>


  );
}

export default App;
AOS.init();
