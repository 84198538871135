import React  from 'react';
import '../Styles/AboutUs.css';
import { Container} from 'react-bootstrap';
import rightIrockMain  from "../Assets/Home/irock2023main.png";

function AboutUs() {
  
    return (
        <div id='mainAboutSec'>
            <Container fluid>
                <div className='flexMainAbout'>
                    <section className='aboutus_block'>
                        <div className='about_irock'>
                            <h1 data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" className='irock_head_about'>About I-Rock</h1>
                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='para_body'>Independence Rock is where India’s rock and metal scene was born, all the way back in 1986.. Ever since then, I-Rock has been THE place where musicians became legends, and legends inspire the next generation of musicians. Every year, we’ve pushed the boundaries more and more (so rock ‘n roll of us right?!) and this year is no exception.</p>
                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='para_body fwMain'>Get ready to rock with</p>
                            <ul>
                                <li data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='para_body'>Big names who you’ve been listening to for years.</li>
                                <li data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='para_body'>Upcoming bands who deserve a bigger stage and louder audience. </li>
                                <li data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='para_body'>Niche forms of rock and metal that are screaming to be heard. </li>
                                <li data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='para_body'>Bands from all over India, and for the first time - from across borders! </li>
                            </ul>
                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='para_body'>They’re all coming soon to a beautifully loud stage near you. </p>

                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" className='para_body'>From saluting yesterday's headliners to giving a stage to tomorrow's.
                            Welcome to Independence Rock 2023. November 4-5.
                            <br></br>
                            <br></br>
                            See you there.</p>
                        </div>
                    </section>
                </div>
            </Container>
        </div>
    )
}

export default AboutUs