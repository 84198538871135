import React from 'react';
import '../Styles/BandHunt.css';
import { Container, Row, Col } from 'react-bootstrap';
import InfographicMobile from "../Assets/Artists/bandhuntM.svg";
import Infographic from "../Assets/Artists/bandHuntPoster.svg";

function BandHunt() {

    return (
        <div>
            <section className='infographicBlock'>
                <div className='centerBh'>
                    {/* <img data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" src={TitleTH} className="titleThImg" alt='Tomorrows headliners independence rock'></img> */}
                    <img data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" src={Infographic} className="infographicBh desktop" alt='Band hunt independence rock'></img>
                    <img data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" src={InfographicMobile} className="infographicBh mobile" alt='Band hunt independence rock'></img>
                    <div className='txtBandHunt'>
                        <h1 data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='subhHeadBh'>Today’s upcoming bands could be Tomorrow’s Headliners.</h1>
                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt'>
                        If you’re a young rock or metal band who is dedicated to your music and craves a bigger audience, you could just get it. Imagine a sea of black tees around your stage, cheering you on, moshing, headbanging, roaring for you to play ONCE MORE ONCE MORE! 
                            <br></br>
                            <br></br>
                            Sounds like the dream? That could be you this November. 
                            <br></br>
                            Mahindra Independence Rock is back and so is Tomorrow’s Headliners - The band hunt for India’s next rock legends. The most iconic band of tomorrow could be jamming in a bedroom or garage somewhere, and we want to give them the stage that has led so many others to greatness. 
                            <br></br>
                            <br></br>
                            Since 1986, I-Rock has been taking young bands that more people deserve to know, and giving them the platform and audience they need to make it big. Among them are some folks you may have heard of — Bhayanak Maut, Parikrama, Tough on Tobacco, and so many more. And now, you could be next.
                            <br></br>
                            <br></br>
                            If you think so too, here’s what you gotta do: 
                            <br></br>
                            *Post a video of an original or cover performed by you on Instagram
                            <br></br>
                            *Use the hashtag #TomorrowsHeadliners and tag us @independencerock
                            <br></br>
                            *Register on this Insider page with your band details
                            <br></br>
                            *Practice your stage moves because you could be selected to perform alongside Bhayanak Maut, Agam, Underside, Gutslit, Parikrama, Tough on Tobacco and more! 
                            <br></br>
                            <br></br>
                            Your entries will be judged by some musicians who may just have inspired you. 
                            <br></br>
                            Subir Malik of Parikrama, Tipriti Kharbangar of Mama Tips & The Mama’s Bois, and Sahil Makhija of Demonic Resurrection will be telling us who they think will be Tomorrow’s Headliners.
                            Let’s get started.
                            <br></br> <br></br>
                            So tune those guitars, tighten your drumheads, and practice your stage moves ‘cos you could be performing at the home of the headbang soon!
                            <br></br> <br></br>
                            <span className='highlightBh'>Please ensure to read the rules and regulations below to ensure your submission is valid.</span>
                        </p>
                        <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='subhHeadBh'>RULES AND REGULATIONS</h2>
                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt'>* Upload a performance video of 2- 5 minutes to Instagram. This can be either a cover or an original track.
                            <br></br>
                            * The performance you’re submitting a video of must be done on or after 1st January 2023.
                            <br></br>
                            * The lyrics of the songs being performed will need to be submitted while filling out your registration form.
                            <br></br>
                            * The Instagram profile on which your performance video is being uploaded must be open to public viewing. No private accounts, please!
                            <br></br>
                            * Please ensure the relevant accounts are tagged (@independencerock) and the right hashtag is being used (#TomorrowsHeadliners). Entries without the right tags and hashtags will be considered invalid.
                            <br></br>
                            * You can submit a performance in English or any Indian language (although lyrics have to be shared in English text)
                            <br></br>
                            * FYI - the judging criteria are up to the judges themselves.</p>
                        <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='subhHeadBh'>WHAT HAPPENS NEXT?</h2>
                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt'>
                            *Our judges will view all valid uploads, score them, and give us their top 3 picks.
                            <br></br>
                            *We’ll announce them and those 3 bands will move onto the final round of the band hunt.
                            <br></br>
                            *They will then have 72 hours from the time of the announcement to upload an original track on Instagram as a collaborative post with @independencerock along with the lyrics. 
                            <br></br>
                            *The post that receives the most engagement is the winner and performs on November 4th at Bayview lawns!
                            </p>
                        {/* faq */}
                        <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='subhHeadBh'>FAQs</h2>
        
                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt faqcardmabh'>1. When do registrations close?
                                    <br></br>
                                    A.  Last date for submission is 14th October 2023. There is no sign-up fee.
                                </p>

                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt faqcardmabh'>2. Can the performance video be a submission of cover or original or original track?
                            <br></br>
                            A. Your performance can be a submission or either a cover or original track.  <br></br>
                            If you qualify to the Top 3, you will need to submit an entry which will need to be a performance of an original track. 
                            </p>


                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt faqcardmabh'>3. How many entry videos can I submit?
                            <br></br>
                            A. ONLY 1
                        </p>
                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt faqcardmabh'>4. Will my entry video be used by Independence Rock?
                            <br></br>
                            A. By submitting your video, you agree that your video can potentially be used in the promotion of Mahindra Independence Rock.
                        </p>

                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt faqcardmabh'>5. How old do I need to be to participate?
                            <br></br>
                            A. You must be 16 years old or above to participate in the competition.
                        </p>

                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in" className='paraBandHunt faqcardmabh'>6. I registered but haven’t received my confirmation email. What should I do?
                            <br></br>
                            A. rocknroll@independencerock.in
                        </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BandHunt